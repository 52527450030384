import { AvailableGastroProductKind, GastroGenericProductKind, GastroKind } from '@models/client'

export const isGastroGenericProduct = (kind?: GastroKind) => {
  if (!kind) return false

  return AvailableGastroProductKind.includes(kind as GastroGenericProductKind)
}

export const getGastroProductName = <T extends { kind: GastroKind }>(gastroProduct: T) => {
  if (['normal', 'wholesale'].includes(gastroProduct.kind)) return 'Podwójna Radość'
  if (['gastro_card_2024', 'gastro_card_2024_wholesale'].includes(gastroProduct.kind)) return 'Podwójna Radość 2024'
  if (['gastro_card_2025', 'gastro_card_2025_wholesale'].includes(gastroProduct.kind)) return 'Podwójna Radość 2025'
  if (gastroProduct.kind === 'gastro_card_2026') return 'Podwójna Radość 2026/2027'
  if (gastroProduct.kind === 'gastro_card_2026_october') return 'Podwójna Radość 2026/2027 Last Minute'
  if (gastroProduct.kind === 'gastro_card_2027') return 'Podwójna Radość 2027'
  if (['gastro_card_boost', 'gastro_card_boost_wholesale'].includes(gastroProduct.kind)) return 'Doładowanie Smaku'
  if (gastroProduct.kind === 'gastro_coupon') return 'Strzał w Dziesiątkę'
  if (gastroProduct.kind === 'gastro_card_dynamic') return 'Podwójna Radość 2025 Plus'
  if (gastroProduct.kind === 'gastro_card_product_package_endless_holiday') return 'Voucher Gastronomiczny'
  return ''
}
